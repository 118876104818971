<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="itemDialogTitle"></span>
    </div>
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="登录账号" prop="loginName">
        <el-input
          v-model="formData.loginName"
          placeholder="请输入登录账号英文、数字"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入用户姓名"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="this.rules.pwd[0].required"
        label="初始密码"
        prop="pwd"
      >
        <el-input
          v-model="formData.pwd"
          placeholder="请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户类型：" prop="identityIdList">
        <el-select
          style="width: 100%"
          multiple
          clearable
          v-model="formData.identityIdList"
          placeholder="请选择"
          @change="handleChange"
        >
          <el-option
            v-for="item in systemMenus"
            :key="item.value"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveItemForm" class="dialog-btn"
      >确 定
      </el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import {passwordCheck} from '@/api/user'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    systemMenus: {
      type: Array,
      default: () => [],
    },
    itemData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },

  data() {
    return {
      itemDialogTitle: "新增账号",
      formData: {
        loginName: null,
        name: null,
        pwd: null,
        identityIdList: [],
      },
      defaultProps: {
        children: "children",
        label: "nickName",
      },
      rules: {
        loginName: [
          {required: true, message: "登录名称不可为空", trigger: "blur"},
        ],
        name: [
          {required: true, message: "账号名称不可为空", trigger: "blur"},
        ],
        pwd: [
          {
            required: true,
            message: "初始密码不可为空",
            trigger: "blur",
          },
        ],
        identityIdList: [
          {required: true, message: "用户类型不可为空", trigger: "blur"},
        ],
      },
    };
  },
  created() {
    if (this.itemData !== null) {
      this.rules.pwd[0].required = false;
      this.itemDialogTitle = "编辑账号";
      this.formData = Object.assign({}, this.itemData);
      this.$nextTick(() => {
        this.initData(this.itemData);
      });
    } else {
      this.itemDialogTitle = "新增账号";
    }
  },
  mounted() {
  },
  methods: {
    initData(val) {
      // TODO
      // 获取系统下用户类型树
      // 设置用户类型回填
      // getMenuByIdentity({ userId: val.id }).then((res) => {
      //   const identityId = res.body.map((i) => i.identityId);
      //   this.$refs.tree.setCheckedKeys(identityId);
      // });
      this.formData.identityIdList = val.identities.map((i) => i.id);
    },
    // 关闭
    close() {
      this.$emit("close");
    },
    handleChange(val) {
      this.formData.identityIdList = val;
    },
    saveItemForm() {
      this.$nextTick(() => {
        this.$refs.formData.validate(async (valid) => {
          // 密码校验
          if (this.rules.pwd[0].required) {
            const formdate = new FormData();
            formdate.append('password', this.formData.pwd,)
            const res = await passwordCheck(formdate)
            if (!res.body) {
              window.$msg('当前新密码不符合密码规范，请重新填写密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)', 2)
              return
            }
          }
          if (valid) {
            if (this.itemData === null) {
              this.$emit("addItem", this.formData);
            } else {
              this.$emit("editItem", this.formData);
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
</style>
