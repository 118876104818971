import { render, staticRenderFns } from "./changePwd.vue?vue&type=template&id=4262666a&scoped=true"
import script from "./changePwd.vue?vue&type=script&lang=js"
export * from "./changePwd.vue?vue&type=script&lang=js"
import style0 from "./changePwd.vue?vue&type=style&index=0&id=4262666a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4262666a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_manage_sbGx/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4262666a')) {
      api.createRecord('4262666a', component.options)
    } else {
      api.reload('4262666a', component.options)
    }
    module.hot.accept("./changePwd.vue?vue&type=template&id=4262666a&scoped=true", function () {
      api.rerender('4262666a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/system/addAccount/changePwd.vue"
export default component.exports