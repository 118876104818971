var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.itemDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "登录账号", prop: "loginName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入登录账号英文、数字" },
                model: {
                  value: _vm.formData.loginName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "loginName", $$v)
                  },
                  expression: "formData.loginName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户姓名" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          this.rules.pwd[0].required
            ? _c(
                "el-form-item",
                { attrs: { label: "初始密码", prop: "pwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）",
                      type: "password",
                    },
                    model: {
                      value: _vm.formData.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "pwd", $$v)
                      },
                      expression: "formData.pwd",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "用户类型：", prop: "identityIdList" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { multiple: "", clearable: "", placeholder: "请选择" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.formData.identityIdList,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "identityIdList", $$v)
                    },
                    expression: "formData.identityIdList",
                  },
                },
                _vm._l(_vm.systemMenus, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveItemForm },
            },
            [_vm._v("确 定 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }