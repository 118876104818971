<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="姓名：">
              <el-input
                clearable
                v-model="searchForm.name"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="状态：">
              <el-select
                clearable
                v-model="searchForm.state"
                placeholder="状态"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="getListHandler">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>账户数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="openAddEditModal(null)"
          >创建账号
        </el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <el-popconfirm
                style="cursor: pointer"
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                :title="`确定要${
                  scope.row.state == '1' ? '停用' : '启用'
                }该用户吗？`"
                @onConfirm="confirmStatus(scope.row)"
                @confirm="confirmStatus(scope.row)"
              >
                <el-tag slot="reference" :type="statusOptions[scope.row.state]">
                  {{ scope.row.state === 1 ? "启用" : "停用" }}
                </el-tag>
              </el-popconfirm>
            </template>
            <template v-else-if="item.label === '创建时间'">
              <span v-if="scope.row.createTime">
                {{ scope.row.createTime.substring(0, 19) }}</span
              >
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '用户类型'">
              <span v-if="scope.row.identities.length !== 0">
                <el-tag
                  style="margin-left: 5px"
                  type="info"
                  v-for="item in scope.row.identities"
                  :key="item.id"
                  >{{ item.name }}
                </el-tag>
              </span>
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button type="text" @click="openAddEditModal(scope.row)"
                >编辑
              </el-button>
              <el-button type="text" @click="openPwdModal(scope.row)"
                >修改密码
              </el-button>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <add-item
      :systemMenus="systemMenus"
      :show="showAddEditDialog"
      v-if="showAddEditDialog"
      @close="
        showAddEditDialog = false;
        itemInfo = null;
      "
      :itemData="itemInfo"
      @addItem="addMember"
      @editItem="editMember"
    />
    <change-pwd
      :show="pwdDialog"
      v-if="pwdDialog"
      @close="() => (pwdDialog = false)"
      @savePass="savePass"
    />
  </div>
</template>
<script>
import getAccountColor from "@/mixins/getStateColor";
import {
  getSysAccountList,
  addSysAccount,
  editSysAccount,
  editSysAccountStatue,
  editSysAccountPwd,
} from "@/api/system/account";
import TextButton from "@/components/TextButton";
import AddItem from "@/components/system/addAccount";
import ChangePwd from "@/components/system/addAccount/changePwd";
import { getIdentityList } from "@/api/system/userType";
import { passwordCheck} from '@/api/user'
// 默认展示列
const baseColSetting = [
  {
    label: "登录账号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "用户类型",
    prop: "identities",
    state: true,
    align: "center",
    width: "200",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: false,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getAccountColor],
  components: { AddItem, TextButton, ChangePwd },
  data() {
    return {
      systemMenus: [], //系统身份
      showAddEditDialog: false,
      pwdDialog: false,
      itemInfo: {},
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      userInfo: {},
      searchForm: {
        name: null,
        state: null,
      },
      statusOptions: {
        1: "success",
        3: "danger",
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        // {
        //   value: '2',
        //   label: '删除',
        // },
        {
          value: "3",
          label: "停用",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
    getIdentityList({
      group: "加盟校管理平台",
    }).then((res) => {
      this.systemMenus =
        res.body && res.body.list && res.body.list.length > 0
          ? res.body.list.map((i) => {
              return {
                ...i,
                nickName: i.name + "_" + i.group,
                children: [],
              };
            })
          : [];
    });
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, pwd, loginName, identityIdList } = value;
      const response = await addSysAccount({
        name,
        pwd,
        loginName,
        state: "1",
        identityIdList,
      });
      if (response.state == "success") {
        this.$message.success("添加成功");
        this.showAddEditDialog = false;
        this.pageInfo.pageNum = 1;
        this.getListHandler();
      } else {
        this.$message.warning("添加失败");
      }
    },
    /**
     * 编辑成员
     */
    async editMember(value) {
      const { id, name, loginName, identityIdList } = value;
      const response = await editSysAccount({
        id,
        name,
        loginName,
        identityIdList,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        this.showAddEditDialog = false;
        this.pageInfo.pageNum = 1;
        this.getListHandler();
      } else {
        this.$message.warning("编辑失败");
      }
    },
    /**
     * 修改成员状态
     */
    confirmStatus(val) {
      const { id } = val;
      editSysAccountStatue({
        id,
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 打开密码框
     */
    openPwdModal(val) {
      this.userInfo = val;
      this.pwdDialog = true;
    },
    /**
     * 修改成员密码
     */
    async savePass(val) {
      const { id } = this.userInfo;
      const { pwdOld, pwdNew } = val;
      // 密码校验
      const formdate = new FormData();
      formdate.append('password',  pwdNew,)
      const res = await passwordCheck(formdate)
      if(!res.body){
        window.$msg('当前新密码不符合密码规范，请重新填写密码（密码必须包含大写字母、小写字母、数字、特殊字符中的三种）', 2)
        return
      }
      editSysAccountPwd({
        userId: id,
        pwdOld,
        pwdNew,
      }).then((res) => {
        if (res.state === "success") {
          this.$message.success("密码修改成功");
          this.userInfo = null;
          this.pwdDialog = false;
          this.getListHandler();
        } else {
          this.$message.error("密码修改失败，请重试");
        }
      });
    },
    /**
     * 获取列表
     */

    async getListHandler() {
      let responseData = await getSysAccountList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });

      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        name: null,
        state: null,
      };
      this.getListHandler();
    },
    /**
     * 打开添加弹窗
     */
    openAddEditModal(row) {
      this.itemInfo = row;
      this.showAddEditDialog = true;
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "@/style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

